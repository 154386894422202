import React from 'react';
import styled from 'styled-components';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import SiteWrapper from '../siteWrapper';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import InfoBox from '../abteilung/infoBox';
import Close from './close';
import Widget from '../fussballWidget';
import ContentMd from '../content';

const Wrapper = styled.div`
    max-width: 1100px;
    margin: auto;
`
const ImageWrapper = styled.div`
    width: 100%;
`
const Header = styled.div`
    padding: 20px;
    font-family: ${props => props.theme.fonts.heading};
`
const Heading = styled.h1`
    font-size: 2.5em;
    margin: 0;
    padding-bottom: 0;
`
const Content = styled.div`
    max-width: 600px;
    margin: auto;
`
const ContentWrapper = styled.div`
    display: flex;
    padding: 10px;
    @media (max-width: 980px) {
        flex-direction: column;
    }
`

const NewsTemplate = (props) => {

    const { title, image, kontakt, ort, time, email, widget, beschreibungMd } = props.pageContext;

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <SiteWrapper modal={modal} title={title}>
                    { modal && <Close to={closeTo}/> }
                    <Wrapper>
                        <Header>
                            <Heading className="animate__animated animate__fadeInLeft">{title}</Heading>
                        </Header>
                        <ImageWrapper className="animate__animated animate__fadeInUp">
                            <GatsbyImage image={getImage(image)} alt={title} />
                        </ImageWrapper>
                        <ContentWrapper>
                            <InfoBox kontakt={kontakt} ort={ort} time={time} email={email} />
                            <Content>
                                <ContentMd toMarkdown>
                                    {beschreibungMd}
                                </ContentMd>
                            </Content>
                        </ContentWrapper>
                        { widget && <Widget widgetId={widget} modal={modal} /> }
                    </Wrapper>
                </SiteWrapper>
            )}
        </ModalRoutingContext.Consumer>
    )
};

export default NewsTemplate;