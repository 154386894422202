import React from "react"
import styled from "styled-components"
import { UserCircle, Time, Map, Envelope } from "styled-icons/boxicons-regular/"

const Outer = styled.div`
  padding: 20px 0;
`
const Wrapper = styled.div`
  padding: 10px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.accent};
`
const Elem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  color: ${props => props.theme.colors.white};
`
const P = styled.p`
  margin-left: 10px;
  font-family: ${props => props.theme.fonts.normal};
  font-weight: 900;
`
const Ico = styled.div`
  color: ${props => props.theme.colors.white};
`
const Mail = styled.a`
  color: ${props => props.theme.colors.white};
  text-decoration: underline;
  margin-left: 10px;
  font-family: ${props => props.theme.fonts.normal};
  font-weight: 900;
`

const InfoBox = ({ kontakt, time, ort, email }) => (
  <Outer>
    <Wrapper>
      <Elem>
        <Ico>
          <UserCircle size={30} />
        </Ico>
        <P>{kontakt}</P>
      </Elem>
      <Elem>
        <Ico>
          <Time size={30} />
        </Ico>
        <P>{time}</P>
      </Elem>
      <Elem>
        <Ico>
          <Map size={30} />
        </Ico>
        <P>{ort}</P>
      </Elem>
      <Elem>
        <Ico>
          <Envelope size={30} />
        </Ico>
        <Mail href={`mailto:${email}`}>{email}</Mail>
      </Elem>
    </Wrapper>
  </Outer>
)

export default InfoBox
